<template>
	<div class="main">
		<!-- 我的钱包 -->
		<div class="nav-lf">
		  <MusicType
		    :width="100"
		    :type="type"
		    :tabsList="tabsListMenu"
		  ></MusicType>
		</div>
		<div class="nav-lf">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	import MusicType from "@/components/content/MusicType.vue";
	export default {
		props: {},
		computed: {},
		components: {
			MusicType
		},
		data(){
			return {
				type: false,
				dropdownList: [],
				tabsListMenu: [],
				// tabs数据
				// tabsList: [
				// {
				// 	name: `${this.$t('accountbalance')}`,
				// 	url: '/personalCenter/myWallet/accountBalance'
				// },
				// {
				// 	name: `${this.$t('bindbankcard')}`,
				// 	url: '/personalCenter/myWallet/BindBank'
				// },
				// {
				// 	name: `${this.$t('Listenpermission')}`,
				// 	url: '/personalCenter/myWallet/AuditionAuthority'
				// },
				// {
				// 	name: `${this.$t('mycoupon')}`,
				// 	url: '/personalCenter/myWallet/myCoupon'
				// }
				// ],
				
				tabsListMusic: [
				// {
				// 	name: `${this.$t('grossearnings')}`,
				// 	url: '/personalCenter/myWallet/totalRevenue'
				// },
				{
					name: `${this.$t('accountbalance')}`,
					url: '/personalCenter/myWallet/accountBalance'
				},
        {
          name: `${this.$t('myintegral')}`,
          url: '/personalCenter/myWallet/integral'
        },
				{
					name: `${this.$t('bindbankcard')}`,
					url: '/personalCenter/myWallet/BindBank'
				},
				{
					name: `${this.$t('Listenpermission')}`,
					url: '/personalCenter/myWallet/AuditionAuthority'
				},
				{
					name: `${this.$t('mycoupon')}`,
					url: '/personalCenter/myWallet/myCoupon'
				}
				],
				tabsListUser: [
				{
					name: `${this.$t('accountbalance')}`,
					url: '/personalCenter/myWallet/accountBalance'
				},
        {
          name: `${this.$t('mydeintegral')}`,
          url: '/personalCenter/myWallet/integral'
        },
				{
					name: `${this.$t('bindbankcard')}`,
					url: '/personalCenter/myWallet/BindBank'
				},
				{
					name: `${this.$t('Listenpermission')}`,
					url: '/personalCenter/myWallet/AuditionAuthority'
				},
				{
					name: `${this.$t('mycoupon')}`,
					url: '/personalCenter/myWallet/myCoupon'
				}
				],
			}
		},
		created() {
			console.log(this.$store.state.identity,this.$store.state.isBoss,'3333333333333')
			if(this.$store.state.identity == 3){
				this.tabsListMenu = this.tabsListMusic
			}else{
				this.tabsListMenu = this.tabsListUser
			}
		},
		methods: {
			
		}
	}
</script>

<style lang="less" scoped>
	.main{
		
		width: 100%;
		::v-deep .nav .el-menu-item.is-active {
			border-bottom: 2px solid @lineColor;
		}
		.nav-lf{
			margin-left: 28px;
			margin-bottom: 16px;
		}
	}
</style>