import { render, staticRenderFns } from "./MyWallet.vue?vue&type=template&id=00afa34b&scoped=true"
import script from "./MyWallet.vue?vue&type=script&lang=js"
export * from "./MyWallet.vue?vue&type=script&lang=js"
import style0 from "./MyWallet.vue?vue&type=style&index=0&id=00afa34b&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00afa34b",
  null
  
)

export default component.exports